<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_service')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.serviceName"
                            :label="$t('service_name')"
                                  dense
                                  outlined
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.serviceDescription"
                            :label="$t('service_description')"
                                  dense
                                  outlined
                    ></v-text-field>
                    <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->

                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createFareForm",
        props: ["success", "editData"],
        computed: {

            serviceNameErrors() {
                const errors = [];
                if (!this.$v.editedData.serviceName.$dirty) return errors;
                !this.$v.editedData.serviceName.required &&
                errors.push("Service Name is required.");
                return errors;
            },
            serviceDescriptionErrors() {
                const errors = [];
                if (!this.$v.editedData.serviceDescription.$dirty) return errors;
                !this.$v.editedData.serviceDescription.required &&
                errors.push("Service Type is required.");
                return errors;
            },
        },
        data() {
            return {
                returnData: [],
                show: false,
                updated: false,
                editedData: {},
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                serviceName: {required},
                serviceDescription: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            formData() {
                axios.get(`TravelTrekkingGuide/GetTravelTrekkingGuideServicesByIDAsync/${this.editData}`).then(response => {
                    this.editedData = response.data
                })
            },
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        ServiceID: this.editedData.serviceID,
                        ServiceName: this.editedData.serviceName,
                        ServiceDescription: this.editedData.serviceDescription,
                    };
                    axios.post("TravelTrekkingGuide/UpdateTravelTrekkingGuideServicesAsync", param).then(response => {
                        if (response.data.success) {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Service added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>