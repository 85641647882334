<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>

        <v-snackbar :timeout="callbackResponse.timeout" color="success" top v-model="snackbar">
            {{callbackResponse.message}}
            <v-btn @click="snackbar = false" dark text>Close</v-btn>
        </v-snackbar>

        <v-dialog max-width="500px" v-model="dialogEdit">
            <editForm
                    :editData="editedDataService"
                    :success="success"
                    @formResponse="onResponse"
                    v-if="dialogEdit"
            />
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogDelete">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogCreate">
            <template v-slot:activator="{ on }">
                <v-btn :success="success" class="ma-4 float-right" color="primary" dark v-on="on">{{$t('add_service')}}</v-btn>
            </template>
            <createForm :success="success" @formResponse="onResponse"/>
        </v-dialog>
        <v-container fluid>

            <vue-good-table :columns="columnsRoom"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true, perPageDropdown: [50, 100,200]}"
                            :rows="roomRows"
                            :totalRows="totalRecords"
                            @on-column-filter="onColumnFilter"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            mode="remote">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                        <v-icon @click="editItem(props.row)" class="mr-2" small>edit</v-icon>
                        <v-icon @click="deleteItemRoom(props.row)" small>delete</v-icon>
                    </span>
                </template>
            </vue-good-table>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import createForm from "./Create";
    import editForm from "./Edit";
    import deleteListData from "@/components/deleteModal";
    import {parseJwt} from "@/mixins/parseJwt";

    export default {
        name: "service_create_form",
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        components: {
            createForm,
            editForm,
            deleteListData
        },
        computed: {
            breadCrumb() {
                return [
                    {
                        text: this.$t('settings'),
                        to: "/TravelTrekkingGuideSettings",
                        disabled: false,
                        exact: true
                    },
                    {
                        text: this.$t('service_setting'),
                        disabled: true
                    }
                ]
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                panel: [0],
                deleteFormData: {},
                popout: true,
                isLoading: false,
                columnsRoom: [

                    {
                        label: "s_no",
                        field: "countIndex"
                    },
                    {
                        label: "service_name",
                        field: "serviceName"
                    },

                    {
                        label: "service_description",
                        field: "serviceDescription"
                    },

                    {
                        label: "actions",
                        field: "actions",
                        sortable: false
                    }
                ],
                roomRows: [],
                totalRecords: 0,
                serverParamsRoom: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "roomNumber",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                user: [],
                editedIndex: -1,
                editedDataRoom: {}
            };
        },

        mounted() {
            this.loadItems();
            this.filterData();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            onResponseDelete(data) {
                if (data) {
                    this.$emit("formResponseRoomDelete", data);
                    this.dialogDelete = false;
                    this.loadItems();
                } else {
                    this.dialogDelete = false;
                }
            },
            editItem(props) {
                this.editedDataService = props.serviceID;
                this.dialogEdit = true;
                // this.dialogEditFare = true;
            },

            deleteItemRoom(props) {
                console.log(props);
                this.dialogDelete = true;
                this.deleteFormData.url = "TravelTrekkingGuide/DeleteTravelTrekkingGuideServicesAsync/" + props.serviceID;
                this.loadItems();
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            searchInput() {
                this.loadItems();
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            async filterData() {
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParamsRoom.perPage,
                    PageNo: this.serverParamsRoom.page,
                    OrderType: this.serverParamsRoom.sort[0].type,
                    OrderBy: this.serverParamsRoom.sort[0].field
                };
                axios.post("TravelTrekkingGuide/GetTravelTrekkingGuideServicesListAsync", param)
                    .then(response => {
                        this.roomRows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                    })
                    .catch(err => {
                        console.log(err)
                    });

            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
</style>
